body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: green !important;
}

.Section__sectionFooterSecondaryContent___Nj41Q {
  visibility: hidden;
}

.Anchor__a___1_Iz8 {
  color: #1a55e5 !important;
  font-weight: bold;
}
